import { useI18nQuery } from 'api/i18n'
import { useGlobalState } from 'global-state'
import { useCallback } from 'react'

export type DictArgs = { [a: string]: string }

function translate(key: string, dict: DictArgs, args?: DictArgs): string {
  if (localStorage.getItem('lotto-show-i18n-keys')) {
    return key
  }

  const translation = dict[key]

  if (args && translation) {
    return Object.entries(args).reduce((acc, [key, val]) => {
      return acc.replaceAll(`{${key}}`, val)
    }, translation)
  }

  return translation || key
}

export function useI18n() {
  const [selectedLanguage] = useGlobalState('selectedLanguage')

  const query = useI18nQuery(selectedLanguage || '')

  return useCallback(
    (key: string, args?: DictArgs) => {
      return query.data ? translate(key, query.data, args) : key
    },
    [query.data, selectedLanguage] // eslint-disable-line
  )
}

import { GameGroups } from 'constants/ticket'
import { sub } from 'date-fns'
import { createGlobalState } from 'react-hooks-global-state'
import {
  ClientMenuOptions,
  SelectedBall,
  FreeTicket,
  SelectedDraw,
  TicketState,
  CombiBetDraw,
  CombiBetTicket,
} from 'shared-types'

export type Ticket = {
  selectedBalls: SelectedBall[]
  confirmed: boolean
  drawTypeKey: string | undefined
  drawOptionKey: string | undefined
  gameGroupId: number
  stake?: number
  quantityOfNumbers: number | undefined
  wasPermutationAccepted: boolean
  fixedStake?: number
}
export type Draws = {
  [drawGroupId in number | string]: Date[]
}

export const hoursFilterOptions = [1, 2, 24] as const

export type HoursFilter = typeof hoursFilterOptions[number]

export type TicketAggr = {
  draws: Draws
  tickets: Ticket[]
  code?: string
  gameGroupId: GameGroups
  prefilledForCashier: boolean
  isJackpot: boolean
  availableDrawsForBrandId: number[] | undefined
}

export const createTicketsDefaultState: (
  args: boolean,
  gameGroup: number
) => TicketState = (isJackpot: boolean, gameGroup) => {
  return {
    drawTypeId: null,
    drawWonTypeId: null,
    drawsIds: [],
    maxStake: null,
    minStake: null,
    potentialWinning: null,
    stake: null,
    isAddedToTicket: false,
    oddsBoosted: null,
    code: null,
    isCashierTicket: false,
    selectedGames: {
      category: null,
      subCategory: null,
      numbers: null,
      pickedBalls: [],
      isPermutationAccepted: false,
      isJackpot: isJackpot,
      isExclusive: gameGroup === 1 ? true : false,
    },
  }
}

export const { useGlobalState, setGlobalState, getGlobalState } =
  createGlobalState<{
    gameGroupId: GameGroups
    hoursFilter: HoursFilter
    countriesFilter: number[]
    jackpotPoolValue: string | boolean
    cashierSearchedBookingCode: string
    selectedLanguage: string | undefined
    ticketHistoryFrom: Date | undefined
    ticketHistoryTo: Date | undefined
    isPremiumMode: boolean
    clientMenuOption: ClientMenuOptions | undefined
    midContainerRef: null | HTMLDivElement
    isSystemBetToggled: boolean | undefined
    isCombiBetToggled: boolean
    freeTicket: FreeTicket
    isSessionExpired: boolean | null
    isWithdrawalError: boolean
    isPaymentCloseButtonClicked: boolean
    isLoggedIn: boolean
    selectedCombiBetDraw: CombiBetDraw | null
    combiBetTickets: CombiBetTicket[]
    selectedTypeOfTicket: string
    selectedDraws: SelectedDraw[] | []
    ticketsState: TicketState[] | []
    jackpotTicketState: TicketState[] | []
    exclusiveTicketState: TicketState[] | []
    selectedTabIndex: number
    cashierTicket: TicketState[]
    cashierDraws: SelectedDraw[] | []
    fromBookingCodeSite: boolean
    isDepositWithdrawalOpen: boolean
  }>({
    freeTicket: {},
    gameGroupId: GameGroups.lotto,
    hoursFilter: 24,
    countriesFilter: [],
    jackpotPoolValue: false,
    cashierSearchedBookingCode: '',
    selectedLanguage:
      window.localStorage.getItem('lotto-selected-language') || undefined,
    ticketHistoryFrom: sub(new Date(), { hours: 1 }),
    ticketHistoryTo: new Date(),
    isPremiumMode: false,
    clientMenuOption: undefined,
    midContainerRef: null,
    isSystemBetToggled: undefined,
    isCombiBetToggled: false,
    isSessionExpired: null,
    isWithdrawalError: false,
    isPaymentCloseButtonClicked: false,
    isLoggedIn: false,
    selectedCombiBetDraw: null,
    combiBetTickets: [],
    selectedDraws: [],
    ticketsState: [createTicketsDefaultState(false, 0)],
    jackpotTicketState: [createTicketsDefaultState(true, 0)],
    exclusiveTicketState: [createTicketsDefaultState(false, 1)],
    selectedTypeOfTicket: '',
    selectedTabIndex: 0,
    cashierTicket: [],
    cashierDraws: [],
    fromBookingCodeSite: false,
    isDepositWithdrawalOpen: false,
  })

export enum GameGroups {
  lotto = 0,
  ghana = 1,
  guinea = 2,
}

export enum CancelReasons {
  notEnoughMoney = 100,
  other = 500,
}

export enum DrawStatuses {
  cancelled = 10,
  closed = 20,
  suspended = 30,
  active = 100,
  finishedResultRegistered = 105,
}

export enum TicketStatuses {
  accepted = 100,
  resulted = 200,
  settled = 300,
  cancelledByCashier = 400,
  cancelledByAdmin = 500,
  lapsed = 600,
}

export enum OutcomeStatuses {
  new = 0,
  won = 1,
  lost = 2,
  firstSetLost = 3,
}

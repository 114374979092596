import { useState, useEffect } from 'react'

const useCheckIfMobile = (mobileThreshold = 900) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= mobileThreshold)

  useEffect(() => {
    const handleWindowSizeChange = () => {
      setIsMobile(window.innerWidth <= mobileThreshold)
    }
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [mobileThreshold])

  return isMobile
}

export default useCheckIfMobile

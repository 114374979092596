import { add } from 'date-fns'
import { useCashierMode } from 'hooks/cashier'
import { useI18n } from 'hooks/i18n'
import { useCallback } from 'react'
import toast from 'react-hot-toast'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router'
import Cookies from 'js-cookie'

export function useCustomFetch() {
  const [isCashier, setCashierMode] = useCashierMode()
  const history = useHistory()
  const i18n = useI18n()
  const query = useQueryClient()

  return useCallback(
    (input: RequestInfo, init?: RequestInit): Promise<Response> => {
      const sessionKey = Cookies.get('X-ODDS-SESSION')
      let modifiedInit = init ? { ...init } : {}
      if (sessionKey) {
        modifiedInit.credentials = modifiedInit.credentials || 'include'

        modifiedInit.headers = {
          ...(modifiedInit.headers || {}),
          'x-odds-session': sessionKey,
        }
      }

      if (window.lottoBrandData?.brandId) {
        modifiedInit.headers = {
          ...(modifiedInit.headers || {}),
          'x-brand-data': window.lottoBrandData.brandId.toString(),
        }
      }

      return fetch(input, modifiedInit).then((res) => {
        if (res.status === 500) {
          toast.error(i18n('unexpected_error'), { id: 'unexpected-error' })
        }
        if (res.status === 401) {
          if (isCashier && !res.url.includes('customer/session/customerData')) {
            setCashierMode(false)
            history.push('/')
          } else if (query.getQueryData('client-data')) {
            query.refetchQueries('client-data')
          }

          if (!res.url.includes('customer/session/customerData')) {
            toast.error(i18n('session_expired'), {
              id: 'session-expired',
            })
          }
        }
        if (res.headers.get('x-odds-session')) {
          const sessionKeyFromHeader = res.headers.get('x-odds-session')
          sessionKeyFromHeader && setSessionKey(sessionKeyFromHeader)
        }
        return res
      })
    },
    [setCashierMode, isCashier, history, i18n, query]
  )
}

export function setSessionKey(sessionKey: string) {
  Cookies.set('X-ODDS-SESSION', sessionKey, {
    ...(process.env.REACT_APP_PERSISTENT_COOKIE && {
      expires: add(new Date(), { years: 1 }),
    }),
    path: '/',
    sameSite: 'none',
    secure: true,
    domain: process.env.REACT_APP_DOMAIN || '',
  })
}

export function usePost() {
  const customFetch = useCustomFetch()

  return useCallback(
    (
      url: string,
      body: undefined | Object | string | number,
      init?: RequestInit
    ) => {
      return customFetch(url, {
        ...init,
        method: 'post',
        headers: {
          ...init?.headers,
          'content-type': 'application/json',
        },
        body: typeof body === 'string' ? body : JSON.stringify(body),
      })
    },
    [customFetch]
  )
}
export function usePut() {
  const customFetch = useCustomFetch()

  return useCallback(
    (url: string, body?: Object | string | number, init?: RequestInit) => {
      return customFetch(url, {
        ...init,
        method: 'put',
        headers: { 'content-type': 'application/json', ...init?.headers },
        body: typeof body === 'string' ? body : JSON.stringify(body),
      })
    },
    [customFetch]
  )
}


import {
  FC,
  lazy,
  StrictMode,
  Suspense,
  useEffect,
  useMemo,
  useState,
} from 'react'
import ReactDOM from 'react-dom'
import { QueryClientProvider } from 'react-query'
import Cookies from 'js-cookie'
import preval from 'preval.macro'
import './index.css'
import { queryClient } from 'query'
import { Spinner } from 'components/spinner'
import { useBrandsQuery } from 'api/brands'

document.body.dataset.buildTimestamp = preval`module.exports = new Date().toUTCString();`
document.body.dataset.commitHash = preval`module.exports =  require('child_process').execSync('git rev-parse --short HEAD').toString().trim();`
document.body.dataset.version = preval`module.exports = require('../package.json').version`

const LazyWelcomePage = lazy(() => import('components/welcome-page'))
const LazyApp = lazy(() => import('components/app'))

const AppOrWelcomePage: FC = () => {
  const { data: brands, isLoading } = useBrandsQuery()

  let params = new URLSearchParams(document.location.search)
  let affiliateId

  for (const [key, value] of params.entries()) {
    if (key.toLowerCase() === 'affiliateid') {
      affiliateId = value
      break
    }
  }

  if (affiliateId) {
    Cookies.set('netReferAffiliateId', affiliateId, { expires: 365 })
  }

  const hasMatchingBrandRouteValue = useMemo(() => {
    if (!brands) return null

    return brands.some(
      (brand) =>
        brand.routingType === 'domain' &&
        brand.routingValue === window.location.origin
    )
  }, [brands])

  if (!brands) {
    return isLoading ? (
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          display: 'flex',
        }}
      >
        <Spinner />
      </div>
    ) : null
  }

  return (
    <Suspense fallback={<Spinner />}>
      {!hasMatchingBrandRouteValue && window.location.pathname === '/' ? (
        <LazyWelcomePage />
      ) : (
        <LazyApp />
      )}
    </Suspense>
  )
}

// Conditional polyfill for very old devices used in Africa.
const PolyfillWrapper: FC = ({ children }) => {
  const [init, setInit] = useState(false)

  useEffect(() => {
    if ('flat' in [] && 'replaceAll' in String.prototype) {
      setInit(true)
    } else {
      // @ts-ignore
      import('core-js/actual').then(() => {
        setInit(true)
      })
    }
  }, [])

  if (!init) return null

  return <>{children}</>
}

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <StrictMode>
      <PolyfillWrapper>
        <AppOrWelcomePage />
      </PolyfillWrapper>
    </StrictMode>
  </QueryClientProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()

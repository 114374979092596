// unfortunately, cannot use proper a type here, because it breaks type inference
// in place where we use it
export const longQueryCacheOptions = {
  cacheTime: 1000 * 60 * 60,
  staleTime: 1000 * 60 * 60,
  retry: false,
  refetchOnWindowFocus: false,
  keepPreviousData: true,
}

export const drawsRefetchInterval = 1000 * 60 * 5

export const shortQueryCacheOptions = {
  ...longQueryCacheOptions,
  cacheTime: 1000 * 60 * 5,
  staleTime: 1000 * 60 * 5,
}

export const veryShortQueryCacheOptions = {
  retry: false,
  refetchOnWindowFocus: true,
  keepPreviousData: true,
  cacheTime: 1000 * 30,
  staleTime: 1000 * 30,
}

export const sessionRefreshThrottleTime = 10_000

export const infiniteQueryCacheOptions = {
  cacheTime: Infinity,
  staleTime: Infinity,
  retry: false,
  keepPreviousData: true,
  refetchOnWindowFocus: false,
}
import toast from 'react-hot-toast'
import { useQuery } from 'react-query'
import useCheckIfMobile from 'hooks/check-if-mobile'
import { infiniteQueryCacheOptions } from 'constants/cache'

function fetchI18n({
  langCode,
  countryCode,
  brandId,
  isMobile,
}: {
  langCode: string
  countryCode: string
  brandId: number
  isMobile: boolean
}) {
  langCode = !langCode ? 'en' : langCode

  return fetch(
    `${
      localStorage.getItem('lotto-backend-url') || ''
    }/static/lang2/lang_${langCode.toLowerCase()}_${countryCode.toLowerCase()}_${brandId}_${
      isMobile ? 2 : 1
    }.json`
  ).then((res) => {
    if (res.ok) {
      return res.json()
    }
    return Promise.reject(res)
  })
}

export function useI18nQuery(selectedLanguage: string) {
  const brand = window.lottoBrandData // cannot use hook because of circular deps
  const isMobile = useCheckIfMobile()
  return useQuery(
    ['i18n', isMobile, brand?.brandId, selectedLanguage],
    () => {
      if (!brand) return null

      return fetchI18n({
        langCode: selectedLanguage,
        countryCode: brand.countryCode,
        brandId: brand.brandId,
        isMobile,
      })
    },
    {
      ...infiniteQueryCacheOptions,
      onError() {
        toast.error('critical error: failed to load i18n dict', {
          id: 'i18n-error',
        })
      },
      enabled: !!brand && brand.languages.includes(selectedLanguage),
    }
  )
}

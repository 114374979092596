import { infiniteQueryCacheOptions } from 'constants/cache'
import { brandsInfo } from 'constants/misc'
import { apiUrl } from 'constants/urls'
import { debugMode } from 'constants/misc'
import { useCallback } from 'react'
import { useQuery } from 'react-query'
import { Brand } from 'shared-types'
import { useCustomFetch } from './shared'
import Cookie from 'js-cookie'

function useFetchBrands() {
  const customFetch = useCustomFetch()

  return useCallback((): Promise<Brand[]> => {
    return customFetch(`${apiUrl}/brands`).then((res) => {
      if (res.ok) return res.json()
      throw res
    })
  }, [customFetch])
}

function selectBrand(brands: Brand[]): Brand | undefined {
  return brands.find((brand) => {
    if (
      debugMode &&
      brand.routingType === 'domain' &&
      window.location.pathname.startsWith(`/${brand.countryCode.toLowerCase()}`)
    ) {
      return brand
    }
    if (brand.routingValue.match(/^(www|http)/)) {
      return window.location.origin.startsWith(brand.routingValue)
    }
    return window.location.pathname.startsWith(brand.routingValue)
  })
}

export function useBrandQuery() {
  const brandsQuery = useBrandsQuery()

  return useQuery(
    'selected-brand',
    () => {
      if (!brandsQuery.data) return undefined

      const brand = selectBrand(brandsQuery.data)

      if (brand) {
        window.lottoBrandData = brand
        Cookie.set('countryCode', brand.countryCode)

        document.body.classList.add(`brandId-${brand.brandId}`)

        const brandInfo = brandsInfo.find(
          (info) => info.countryCode === brand.countryCode
        )

        if (brandInfo?.customTitle) {
          document.title = brandInfo.customTitle
        }

        if (brandInfo?.customDescription) {
          const description = document.head.querySelector<HTMLMetaElement>(
            'meta[name="description"]'
          )
          if (description) {
            description.content = brandInfo.customDescription
          } else {
            console.error('meta description not found')
          }
        }
      }

      return brand
    },
    {
      ...infiniteQueryCacheOptions,
      enabled: !!brandsQuery.data,
    }
  )
}

export function useBrandsQuery() {
  return useQuery('brands', useFetchBrands(), infiniteQueryCacheOptions)
}

import { createGlobalState } from 'react-hooks-global-state'
import { useLayoutEffect } from 'react'

const storageKey = 'lotto-cashier-mode'

const { useGlobalState: useGlobalCashierMode } = createGlobalState<{
  flag: boolean
}>({ flag: !!window.localStorage.getItem(storageKey) })

export function useCashierMode() {
  const args = useGlobalCashierMode('flag')
  const isCashier = args[0]

  useLayoutEffect(() => {
    if (isCashier) {
      window.localStorage.setItem(storageKey, 'x')
    } else {
      window.localStorage.removeItem(storageKey)
    }
  }, [isCashier])

  return args
}

